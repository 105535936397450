import React from "react"
import { Link } from "gatsby"
import Card from "@material-ui/core/Card"
import Typography from "@material-ui/core/Typography"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import CardActionArea from "@material-ui/core/CardActionArea"
import GatsbyImage from "gatsby-image"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  root: {
    margin: "32px 0"
  },
  media: {
    height: 0,
    paddingTop: '50%', // 16:9,
  },
  keywords: {
    margin: "6px 12px",
  },
})

const ArticleCard = ({ post }) => {
  const classes = useStyles()
  let featuredImgFluid = post.frontmatter.featuredImage?.childImageSharp?.fluid

  return (
    <Card component={"article"} className={classes.root}>
      <CardActionArea component={Link} to={post.fields.slug}>
        <CardMedia
          className={classes.media}
          fluid={featuredImgFluid}
          title=""
          component={GatsbyImage}
        />
        <CardContent>


          <Typography gutterBottom variant="h4" component="h2">
            {post.frontmatter.title}
          </Typography>

          <Typography variant="body2" color="textSecondary" component="p">
            {post.frontmatter.description || post.excerpt}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        {/*<Button color="primary" component={Link} to={post.fields.slug}>*/}
      {/*    Read More*/}
      {/*  </Button>*/}
      {
        post.frontmatter.keywords &&
        <Typography className={classes.keywords} color="textSecondary" variant={"body2"}>
          {
            post.frontmatter.keywords.map((keyword, index) => <span>{`#${keyword} `}</span>)
          }
        </Typography>
      }
      </CardActions>
    </Card>
  )
}

export default ArticleCard
